import "picturefill";
import "intersection-observer";

/* Modules
==================== */
// ドロワー
import drawer from "./modules/drawer";
drawer();

// スライダー
import swiper from "./modules/swiper";
swiper();

// fadeIn
import fadeIn from "./modules/fadeIn";
fadeIn();

// スムーススクロール
import smoothScroll from "./modules/smoothScroll";
smoothScroll();

// アコーディオン
import accordion from "./modules/accordion";
accordion();

// スクロールイベント
import scrollEvent from "./modules/scrollEvent";
scrollEvent();

// ヘッダー
import header from "./modules/header";
header();

// form
import "./modules/form";
