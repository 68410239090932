import {
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock';
export default function menu(drawer) {

  //  ドロワー
  var state = false;
  $("#drawerBtn").on("click", function () {
    if (state == false) {
      document.documentElement.style.overflow = 'hidden';
      disableBodyScroll(document.querySelector('.drawerModal'));
      $('.drawerBtn').addClass('active');
      $('.drawerModal').addClass('open');
      $('.drawerOverlay').addClass('open');
      state = true;
    } else {
      document.documentElement.style.overflow = '';
      enableBodyScroll(document.querySelector('.drawerModal'));
      $('.drawerBtn').removeClass('active');
      $('.drawerModal').removeClass('open');
      $('.drawerOverlay').removeClass('open');
      state = false;
    }
  });

  $('.drawerOverlay').on('click', function () {
    $('.drawerBtn').click();
    $('.drawerModal').removeClass('open');
    state = false;
  });

  $('.drawerModal__item > a').on('click', function () {
    $('.drawerBtn').click();
  });
}
