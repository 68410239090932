document.addEventListener('DOMContentLoaded', function() {
  const form = document.getElementById('snow-monkey-form-17');

  if (form) {
      const errorMessage = document.createElement('div');
      errorMessage.className = 'custom-error-message';
      errorMessage.style.color = '#e64600';
      errorMessage.style.display = 'none';
      errorMessage.innerText = 'メールアドレスが一致しません。';

      // 動的要素監視：フォーム全体でinputイベントを監視
      form.addEventListener('input', function(event) {
          if (event.target.name === 'confirm-email') {
              const emailInput = form.querySelector('input[name="email"]');
              const emailConfirmInput = event.target;
              const submitButton = form.querySelector('button[data-action="confirm"], input[type="submit"]');

              // 初回append対応
              if (!form.querySelector('.custom-error-message')) {
                  emailConfirmInput.parentNode.appendChild(errorMessage);
              }

              if (emailInput && emailConfirmInput) {
                  if (emailInput.value !== emailConfirmInput.value) {
                      errorMessage.style.display = 'block';
                      submitButton && (submitButton.disabled = true);
                  } else {
                      errorMessage.style.display = 'none';
                      submitButton && (submitButton.disabled = false);
                  }
              }
          }
      });

      // フォーム送信イベントも監視
      form.addEventListener('submit', function(event) {
          const emailInput = form.querySelector('input[name="email"]');
          const emailConfirmInput = form.querySelector('input[name="confirm-email"]');
          const submitButton = form.querySelector('button[data-action="confirm"], input[type="submit"]');

          if (emailInput.value !== emailConfirmInput.value) {
              errorMessage.style.display = 'block';
              submitButton && (submitButton.disabled = true);
              event.preventDefault();
          } else {
              submitButton && (submitButton.disabled = false);
          }
      });

      // MutationObserverで動的再生成も監視
      const observer = new MutationObserver(() => {
          const emailConfirmInput = form.querySelector('input[name="confirm-email"]');
          if (emailConfirmInput && !form.querySelector('.custom-error-message')) {
              emailConfirmInput.parentNode.appendChild(errorMessage);
          }
      });

      observer.observe(form, { childList: true, subtree: true });
  }
});
